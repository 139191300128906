body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', 'IBM Plex Sans', Helvetica, Arial, sans-serif; 
  color: black;
  background-color: #2828f1;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Inter', 'IBM Plex Sans Condensed', sans-serif;
  font-weight: 700;
}

button {
  background-color: #A7F46A;
  color: black;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-family: 'Inter', 'IBM Plex Sans', Helvetica, Arial, sans-serif;
  font-weight: bold; 
}



input {
  font-family: 'Inter', 'IBM Plex Sans', Helvetica, Arial, sans-serif;
}

.app-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main-content {
  flex-grow: 1;
  padding: 0px;
  margin-bottom: 60px;
}

.main-header {
  background-color: black;
  color: white;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  height: 50px;
}

.main-nav {
  display: flex;
  z-index: 10;
  align-items: center;
}

.nav-link {
  color: white;
  text-decoration: none;
  margin: 0 10px;
}

.sign-out-button {
  margin-left: 10px;
}

.footer-loggedin {
  background-color: black;
  color: white;
  padding: 1rem;
  text-align: center;
  /**position: fixed; **/
  bottom: 0;
  width: 100%;
}

.footer-loggedin  .nav-link
{
  color: white; 
}

.footer-loggedin .nav-icon svg
{
  color: #A7F46A; 
}

/* ... (previous styles remain the same) ... */
td,  th {
  border: 1px solid #ddd;
  padding: 8px;
}

tr:nth-child(even){background-color: #f2f2f2;}

tr:hover {background-color: #ddd;}

th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  color: black;
  background: #f2f2f2;
}



.add-item-container {
  margin-bottom: 20px;
}

.toggle-add-form {
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 10px;
}

.add-item-form {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 5px;
}

.add-item-form input {
  flex: 1;
  min-width: 200px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.add-item-form button {
  padding: 10px 20px;
  background-color: rgb(0, 153, 255);
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.inventory-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}


.quantity-control {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 150px;
}

.quantity-control button {
  background-color: rgb(0, 153, 255);
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.quantity-control input {
  width: 50px;
  text-align: center;
  border: 1px solid #ccc;
  padding: 5px;
}



.inventory-table button:hover {
  background-color: #F5F8FE; 
}

/* ... (previous styles remain the same) ...

.sidebar {
  position: fixed;
  top: 0;
  right: -350px;
  width: 350px;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease-in-out;
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
}

.main-content.full-width {
  margin-left: 0;
  width: 100%;
}

.sidebar.show {
  right: 0;
}

.close-sidebar {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color:rgb(0, 122, 204); 
}

.close-sidebar:hover 
{
  background: none; 
}

.order-details {
  margin-bottom: 20px;
}

.order-details input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.sidebar-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.sidebar-item button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.order-total {
  margin-top: 20px;
  font-weight: bold;
  font-size: 18px;
}

.create-order-btn {
  width: 100%;
  padding: 10px;
  background-color: rgb(0, 153, 255);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
}

.create-order-btn:hover {
  background-color: rgb(0, 122, 204);
}

.. */


/* Make the table responsive */
@media (max-width: 768px) {
  .inventory-table {
    font-size: 14px;
  }
  
  .inventory-table th,
  .inventory-table td {
    padding: 8px;
  }
  
  .sidebar {
    width: 100%;
    right: -100%;
  }
  
  .sidebar.show {
    right: 0;
  }
}

.sales-orders-table,
.purchase-orders-table {
width: 100%;
border-collapse: collapse;
margin-top: 20px;
}

.sales-orders-table th,
.sales-orders-table td,
.purchase-orders-table th,
.purchase-orders-table td {
border: 1px solid #F5F8FE;
padding: 12px;
text-align: left;
}

.sales-orders-table th:hover,
.purchase-orders-table th:hover {
background-color: #F5F8FE;
}

.sales-orders-table tr:nth-child(even),
.purchase-orders-table tr:nth-child(even) {
background-color: #F5F8FE;
}

.sales-orders-table tr:hover,
.purchase-orders-table tr:hover {
background-color: #F5F8FE;
}

.home-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.dashboard-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.dashboard-item {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dashboard-item h2 {
  margin-bottom: 10px;
  color: #333;
}

.dashboard-item p {
  font-size: 24px;
  font-weight: bold;
  color: rgb(0, 153, 255);
}

/* ... (rest of the styles remain the same) ... */

/* ... (previous styles remain the same) ... */

.welcome-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: #f5f5f5;
}

.welcome-container h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
  color: #333;
}

.welcome-container p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: #666;
}

.auth-buttons {
  display: inline-grid;
  gap: 1rem;
}

.auth-button {
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  text-decoration: none;
  color: white;
  background-color: rgb(0, 153, 255);
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.auth-button:hover {
  background-color: rgb(0, 122, 204);
}


.edit-modal {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5);
display: flex;
justify-content: center;
align-items: center;
}

.edit-modal form {
background-color: white;
padding: 20px;
border-radius: 5px;
width: 500px;
}

.edit-modal h2 {
margin-top: 0;
}

.edit-modal label {
display: block;
margin-bottom: 10px;
}

.edit-modal input {
width: 100%;
padding: 5px;
margin-top: 5px;
}

.edit-modal button {
margin-top: 10px;
margin-right: 10px;
padding: 5px 10px;
background-color: #007bff;
color: white;
border: none;
border-radius: 3px;
cursor: pointer;
}

.edit-modal button[type="button"] {
background-color: #6c757d;
}

.signup-container {
max-width: 400px;
margin: 0 auto;
padding: 20px;
background: #fff;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
height: 50%; 
border-radius: 30px;
vertical-align: middle;
}

.signup-container h2
{
color: black; 
}

.signup-container form {
display: flex;
flex-direction: column;
}

.signup-container input,
.signup-container select {
margin-bottom: 10px;
padding: 10px;
border: 1px solid #ddd;
border-radius: 4px;
}

.signup-container button {
padding: 10px;
background-color: #007bff;
color: white;
border: none;
border-radius: 4px;
cursor: pointer;
}

.signup-container button:hover {
background-color: #0056b3;
}

.error {
color: red;
margin-bottom: 10px;
}

.location-search-input {
width: 100%;
}

.autocomplete-dropdown-container {
position: absolute;
z-index: 1000;
background-color: white;
border: 1px solid #ddd;
border-radius: 4px;
width: 100%;
max-height: 200px;
overflow-y: auto;
}

.suggestion-item {
padding: 10px;
cursor: pointer;
}

.suggestion-item--active {
background-color: #fafafa;
}

.search-bar {
width: 100%;
margin-right: 2%;
}

.search-bar input {
width: 100%;
padding: 10px;
font-size: 16px;
}

.add-item-form {
display: flex;
flex-wrap: wrap;
gap: 10px;
margin-bottom: 20px;
}

.add-item-form input {
flex: 1;
min-width: 150px;
padding: 10px;
}

.add-item-form button {
padding: 10px 20px;
background-color: #007bff;
color: white;
border: none;
cursor: pointer;
}

.inventory-table {
width: 100%;
border-collapse: collapse;
}

.inventory-table th,
.inventory-table td {
border: 1px solid #ddd;
padding: 12px;
text-align: left;
}

.inventory-table th {
background-color: #f2f2f2;
}

.inventory-table button {
margin-right: 5px;
padding: 5px 10px;
background-color: transparent;
color: black;
border: none;
cursor: pointer;
}

.low-stock {
background-color: #ffcccc;
}

.moderate-stock {
background-color: #fff2cc;
}


.modal {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5);
display: flex;
justify-content: center;
align-items: center;
}

.modal form {
background-color: white;
padding: 20px;
border-radius: 5px;
width: 500px;
}

.modal h2 {
margin-top: 0;
}

.modal input {
width: 80%;
padding: 10px;
margin-bottom: 10px;
}

.modal button {
margin-right: 10px;
padding: 10px 20px;
background-color: #007bff;
color: white;
border: none;
cursor: pointer;
}

.import-export {
margin: 20px 0;
display: flex;
gap: 10px;
}

.import-export button {
padding: 10px;
background-color: #A7F46A;
color: white;
border: none;
cursor: pointer;
}

.import-export button:disabled {
background-color: #cccccc;
cursor: not-allowed;
}

.import-export input[type="file"] {
padding: 10px;
border: 1px solid #ddd;
}

.App {
text-align: center;
}

.App-logo {
height: 40vmin;
pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
.App-logo {
  animation: App-logo-spin infinite 20s linear;
}
}

.App-header {
background-color: #282c34;
min-height: 100vh;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
font-size: calc(10px + 2vmin);
color: white;
}

.App-link {
color: #61dafb;
}

@keyframes App-logo-spin {
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
}

.sidebar-menu {
position: fixed;
top: 0;
left: 0;
height: 100vh;
background-color: #fbfaff;
transition: all 0.3s ease;
overflow-x: hidden;
z-index: 1000;
font-weight: 500; 
}

.sidebar-menu.expanded {
width: 250px;
}

.sidebar-menu.collapsed {
width: 60px;
}

.sidebar-header {
display: flex;
align-items: center;
justify-content: space-between;
padding: 20px;
}

.logo {
height: 40px;
width: auto;
}

.toggle-btn {
background: none;
border: none;
color: white;
font-size: 20px;
cursor: pointer;
}

.user-info {
padding: 10px 20px;
color: white;
}

.sidebar-nav {
padding: 20px 0;
}

.nav-link {
display: flex;
align-items: center;
padding: 10px 20px;
text-decoration: none;
transition: background-color 0.3s ease;
}

.nav-link:hover {
color: #34495e;
}

.nav-link svg {
margin-right: 15px;
color: #545a6d;
}

.sign-out-button {
display: flex;
align-items: center;
width: 100%;
padding: 10px 20px;
background: none;
border: none;
color: white;
cursor: pointer;
transition: background-color 0.3s ease;
}

.sign-out-button:hover {
background-color: #c0392b;
}

.sign-out-button svg {
margin-right: 15px;
}

.mobile-toggle {
display: none;
}

@media (max-width: 768px) {
.sidebar-menu {
  transform: translateX(-100%);
  width: 100%;
}

.sidebar-menu.expanded {
  transform: translateX(0);
}

.mobile-toggle {
  display: block;
  position: fixed;
  top: 10px;
  left: 120px;
  z-index: 1001;
  background: none;
  border: none;
  font-size: 24px;
  width: 70px;
  color: #2c3e50;
}

.sidebar-menu.mobile .sidebar-header .toggle-btn {
  display: none;
}

.sidebar-menu.mobile .nav-link,
.sidebar-menu.mobile .sign-out-button {
  padding: 15px 20px;
}
}



.main-header {
background-color: #f5f5f5;
color: white;
padding: 10px 20px;
}

.header-content {
display: flex;
justify-content: space-between;
align-items: center;
}

.logo-container {
display: flex;
align-items: center;
}

.logo {
height: 40px;
}

.nav-toggle {
display: none;
background: none;
border: none;
cursor: pointer;
}

.hamburger {
display: block;
width: 30px;
height: 3px;
background-color: black;
position: relative;
}

.nav-toggle:hover
{
background: none;
}

.hamburger::before,
.hamburger::after {
content: '';
position: absolute;
width: 100%;
height: 3px;
background-color: black;
}

.hamburger::before {
top: -8px;
}

.hamburger::after {
bottom: -8px;
}

.main-nav {
display: flex;
align-items: center;
}

.nav-link {
color:black;
text-decoration: none;
padding: 10px;
margin: 0 5px;
}

.sign-out-button {
background-color: #f44336;
color: white;
border: none;
padding: 10px 15px;
cursor: pointer;
margin-left: 10px;
}

@media (max-width: 768px) {
.nav-toggle {
  display: block;
}

.main-nav {
  display: none;
  flex-direction: column;
  position: absolute;
  left: 0;
  right: 0;
  background-color: #f5f5f5;
  padding: 20px;
}

.main-nav.open {
  display: flex;
}

.nav-link {
  margin: 10px 0;
}

.sign-out-button {
  margin: 10px 0;
}
}


.app-container {
display: flex;
background: #f7f7f7;
padding: 0.5em; 
}

.sidebarMenu {
position: fixed;
top: 0;
left: 0;
height: 100vh;
background-color: white;
transition: width 0.3s ease;
overflow-x: hidden;
z-index: 1000;
padding: 10px 20px;
}

.sidebarMenu.expanded {
width: 250px;
}

.sidebarMenu.collapsed {
width: 60px;
}

.sidebarMenu-header {
display: flex;
align-items: center;
justify-content: space-between;
}


.toggle-btn {
background: none;
border: none;
color: black;
font-size: 20px;
cursor: pointer;
}

.user-info {
padding: 10px 20px;
color: black;
}

.sidebarMenu-nav {
padding: 20px 0;
}

.hamburger-button
{
position: fixed;
top: 10px;
right: 100px; 
z-index: 1001;
background: #1B1AFF;
color: white;
}

.nav-link {
display: flex;
align-items: left;
width: 100%;
color: black;
text-decoration: none;
transition: background-color 0.3s ease;
}



.sign-out-button {
display: flex;
align-items: center;
width: 100%;
padding: 10px 20px;
background: none;
border: none;
color: red;
cursor: pointer;
transition: background-color 0.3s ease;
}

.sign-out-button svg {
margin-right: 5px;
}

.main-content:not(.frontend) {
flex-grow: 1;
transition: margin-left 0.3s ease;
min-height: 100vh;
padding: 0.5em; 
padding-top: 5em;
}

.xero-container {
padding: 20px;
}

.xero-header {
display: flex;
justify-content: space-between;
align-items: center;
margin-bottom: 20px;
}

.xero-title {
font-size: 24px;
font-weight: bold;
}

.xero-button {
padding: 8px 16px;
background-color: #007bff;
color: white;
border: none;
border-radius: 4px;
cursor: pointer;
min-width: 120px;
}

.xero-button:disabled {
background-color: #cccccc;
cursor: not-allowed;
}

.xero-error {
padding: 12px;
background-color: #ffebee;
color: #c62828;
border-radius: 4px;
margin-bottom: 20px;
}

.xero-success {
padding: 12px;
background-color: #e8f5e9;
color: #2e7d32;
border-radius: 4px;
margin-bottom: 20px;
}

.xero-table {
width: 100%;
border-collapse: collapse;
margin-top: 20px;
}

.xero-table th {
text-align: left;
padding: 12px;
border-bottom: 2px solid #ddd;
background-color: #f5f5f5;
}

.xero-table td {
padding: 12px;
border-bottom: 1px solid #ddd;
}

.loading-spinner {
display: inline-block;
width: 12px;
height: 12px;
border: 2px solid #ffffff;
border-radius: 50%;
border-top-color: transparent;
animation: spin 1s linear infinite;
margin-right: 8px;
}

@keyframes spin {
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
}

.button-content {
display: flex;
align-items: center;
justify-content: center;
}

.home-container {
padding: 20px;
}

.dashboard-grid {
display: grid;
grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
gap: 20px;
margin: 20px 0;
}

.dashboard-item {
background: white;
padding: 20px;
border-radius: 8px;
box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.dashboard-item h2 {
margin: 0;
color: #666;
font-size: 16px;
}

.dashboard-item p {
margin: 10px 0 0;
font-size: 24px;
font-weight: bold;
color: #333;
}

.dashboard-item.special {
background: #f8f9ff;
border: 1px solid #e0e7ff;
}

.margin-indicator {
display: block;
font-size: 14px;
color: #666;
margin-top: 5px;
}

.low-stock-alert {
margin-top: 30px;
padding: 20px;
background: #fff3cd;
border: 1px solid #ffeeba;
border-radius: 8px;
}

.low-stock-items {
display: grid;
grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
gap: 15px;
margin-top: 15px;
}

.low-stock-item {
background: white;
padding: 15px;
border-radius: 6px;
display: flex;
justify-content: space-between;
align-items: center;
}

.low-stock-item .item-name {
font-weight: 600;
}

.low-stock-item .stock-level {
color: #dc3545;
}

.low-stock-item .reorder-point {
color: #666;
}


/**CHARTS **/

/* ... existing CSS ... */

.charts-container {
margin-top: 30px;
}

.chart-controls {
margin-bottom: 20px;
}

.date-range-selector {
padding: 8px 12px;
border-radius: 4px;
border: 1px solid #ddd;
font-size: 14px;
background-color: white;
}

.chart-grid {
display: grid;
grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
gap: 20px;
margin-bottom: 30px;
}

.chart-card {
background: white;
padding: 20px;
border-radius: 8px;
box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.chart-card h3 {
margin: 0 0 20px 0;
color: #333;
font-size: 18px;
}

@media (max-width: 768px) {
.chart-grid {
  grid-template-columns: 1fr;
}
}

.charts-container {
margin-top: 2rem;
padding: 1rem;
}

.chart-controls {
margin-bottom: 1rem;
}

.date-range-selector {
padding: 0.5rem;
border-radius: 4px;
border: 1px solid #ddd;
}

.chart-grid {
display: grid;
grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
gap: 1rem;
}

.chart-card {
background: white;
padding: 1rem;
border-radius: 8px;
box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.chart-card h3 {
margin-bottom: 1rem;
color: #333;
}

.no-data {
height: 350px;
display: flex;
align-items: center;
justify-content: center;
color: #666;
font-style: italic;
}

@media (max-width: 768px) {
.chart-grid {
  grid-template-columns: 1fr;
}
}

/** HEADER STUFF **/ 
.header {
position: fixed;
top: 0;
left: 0;
right: 0;
background: #1B1AFF;
color: white; 
display: flex;
align-items: center;
margin: 0 auto;
height: 70px;
padding: 0 20px 0 0;
box-shadow: 0 2px 4px rgba(0,0,0,0.1);
z-index: 1000;
flex-wrap: wrap;
justify-content: space-between;
align-items: center;

}

.header-left {
}

.header-right {
display: flex;
margin-left: auto;
margin-right: 20px;
align-items: center;
justify-content: flex-end;
}

.menu-button {
display: none;
background: none;
border: none;
padding: 10px;
cursor: pointer;
color: white;
}

.logo {
font-size: 1.5rem;
font-weight: bold;
text-decoration: none;
color: white;
margin-left: 20px;
}

.search-container {

max-width: 200px;
margin: 0 20px;
position: relative;
}

.search-container.active .search-input {
display: block;
width: 100%;
}

.search-input {
width: 100%;
padding: 10px 40px;
border: 1px solid #ddd;
border-radius: 6px;
font-size: 14px;
}

.search-icon {
position: absolute;
left: 12px;
top: 50%;
transform: translateY(-50%);
color: white;
}

.close-search {
position: absolute;
right: -95px;
top: 55%;
transform: translateY(-50%);
background: none;
border: none;
cursor: pointer;
color: white;
}

.notification-button,
.settings-button {
background: none;
border: none;
padding: 8px;
cursor: pointer;
color: white;
position: relative;
}

.notification-badge {
position: absolute;
top: 0;
right: 0;
color: white;
font-size: 12px;
padding: 2px 6px;
border-radius: 10px;
min-width: 20px;
text-align: center;
}

.notifications-dropdown {
position: absolute;
top: 100%;
right: 0;
background: white;
border: 1px solid #ddd;
border-radius: 8px;
box-shadow: 0 4px 6px rgba(0,0,0,0.1);
width: 300px;
max-height: 400px;
overflow-y: auto;
padding: 16px;
}

.notifications-dropdown h3 {
margin: 0 0 12px 0;
color: #333;
}

.notification-item {
padding: 12px 0;
border-bottom: 1px solid #eee;
}

.notification-item:last-child {
border-bottom: none;
}

.notification-time {
font-size: 12px;
color: #666;
}

.no-notifications {
color: #666;
text-align: center;
padding: 20px 0;
}

.profile-link {
text-decoration: none;
}

.profile-photo {
width: 36px;
height: 36px;
border-radius: 50%;
object-fit: cover;
overflow: hidden;
aspect-ratio: 1;
}

.profile-photo img {
width: 100%;
height: 100%;
object-fit: cover;
border-radius: 50%;
}

.profile-placeholder {
width: 36px;
height: 36px;
border-radius: 50%;
background: #e9ecef;
color: #666;
display: flex;
align-items: center;
justify-content: center;
font-weight: bold;
}

@media (max-width: 768px) {
.menu-button {
  display: block;
}

.search-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  margin: 0;
  padding: 0 20px;
  display: flex;
  align-items: center;
  transform: translateY(-100%);
  transition: transform 0.3s ease;
}

.search-container.active {
  transform: translateY(0);
}

.search-button {
  background: none !important;
  border: none;
  padding: 8px;
  cursor: pointer;
  color: white;
}

.logo {
  margin-left: 10px;
}
}

/* Add these styles to your existing Header.css */

.notifications-dropdown {
position: absolute;
top: 100%;
right: 0;
background: white;
border: 1px solid #ddd;
border-radius: 8px;
box-shadow: 0 4px 12px rgba(0,0,0,0.1);
width: 360px;
max-height: 480px;
overflow: hidden;
display: flex;
flex-direction: column;
margin-top: 8px;
}

.notifications-header {
display: flex;
justify-content: space-between;
align-items: center;
padding: 16px;
border-bottom: 1px solid #eee;
}

.notifications-header h3 {
margin: 0;
font-size: 16px;
color: #333;
}

.mark-all-read {
background: none;
border: none;
color: #2563eb;
font-size: 14px;
cursor: pointer;
padding: 4px 8px;
border-radius: 4px;
}

.mark-all-read:hover {
background: #f1f5ff;
}

.notifications-list {
overflow-y: auto;
max-height: 400px;
}

.notification-item {
padding: 12px 16px;
border-bottom: 1px solid #eee;
cursor: pointer;
display: flex;
align-items: center;
gap: 12px;
transition: background-color 0.2s;
}

.notification-item:hover {
background-color: #f8fafc;
}

.notification-item.unread {
background-color: #f0f9ff;
}

.notification-item.unread:hover {
background-color: #e0f2fe;
}

.notification-content {
flex: 1;
}

.notification-content h4 {
margin: 0 0 4px 0;
font-size: 14px;
color: #333;
}

.notification-content p {
margin: 0;
font-size: 13px;
color: #666;
line-height: 1.4;
}

.notification-time {
font-size: 12px;
color: #94a3b8;
display: block;
margin-top: 4px;
}

.unread-indicator {
width: 8px;
height: 8px;
border-radius: 50%;
background-color: #2563eb;
flex-shrink: 0;
}

.notifications-loading {
padding: 20px;
text-align: center;
color: #666;
}

.no-notifications {
padding: 32px 16px;
text-align: center;
color: #666;
margin: 0;
}

@media (max-width: 768px) {
.notifications-dropdown {
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  width: auto;
  max-height: calc(100vh - 70px);
  margin: 0;
  border-radius: 0;
}
}

.header-content {
display: flex;
align-items: center;
gap: 6px;
white-space: nowrap;
}

.tooltip-container {
position: relative;
display: inline-flex;
align-items: center;
}

.info-icon {
color: #6b7280;
cursor: help;
}

.info-icon:hover {
color: #3b82f6;
}

.tooltip-text {
visibility: hidden;
position: absolute;
top: 100%;
left: 50%;
transform: translateX(-50%);
background-color: #1f2937;
color: white;
text-align: center;
padding: 8px 12px;
border-radius: 6px;
font-size: 12px;
font-weight: normal;
white-space: nowrap;
z-index: 100;
margin-top: 8px;
min-width: 200px;
max-width: 300px;
white-space: normal;
line-height: 1.4;
box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
}

/* Arrow */
.tooltip-text::after {
content: "";
position: absolute;
bottom: 100%;
left: 50%;
margin-left: -5px;
border-width: 5px;
border-style: solid;
border-color: transparent transparent #1f2937 transparent;
}

/* Show the tooltip on hover */
.tooltip-container:hover .tooltip-text {
visibility: visible;
animation: fadeIn 0.2s ease-in-out;
}

@keyframes fadeIn {
from {
  opacity: 0;
  transform: translateX(-50%) translateY(-10px);
}
to {
  opacity: 1;
  transform: translateX(-50%) translateY(0);
}
}

/* For mobile screens */
@media (max-width: 768px) {
.tooltip-text {
  position: fixed;
  left: 50%;
  bottom: 20px;
  top: auto;
  transform: translateX(-50%);
  margin: 0;
  width: 90%;
  max-width: none;
}

.tooltip-text::after {
  display: none;
}
}

.my-account-container {
max-width: 1200px;
margin: 0 auto;
padding: 20px;
}

.my-account-container h1 {
margin-bottom: 30px;
color: #333;
}

/* React Tabs Customization */
.react-tabs {
background: white;
border-radius: 8px;
box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.react-tabs__tab-list {
border-bottom: 1px solid #ddd;
margin: 0;
padding: 0;
display: flex;
list-style-type: none;
}

.react-tabs__tab {
padding: 12px 24px;
cursor: pointer;
color: #666;
border: none;
border-bottom: 2px solid transparent;
margin-right: 4px;
transition: all 0.3s ease;
}

.react-tabs__tab--selected,
.react-tabs__tab.is-selected {
color: #2563eb;
border-bottom: 2px solid #2563eb;
font-weight: 500;
}

.react-tabs__tab:focus {
outline: none;
box-shadow: none;
}

.react-tabs__tab-panel {
display: none;
padding: 20px;
}

.react-tabs__tab-panel--selected {
display: block;
}

/* Tab Content Styles */
.tab-content {
padding: 20px 0;
}

.tab-content h2 {
margin-bottom: 24px;
color: #333;
}

/* Form Styles */
.form-group {
margin-bottom: 20px;
}

.form-group label {
display: block;
margin-bottom: 8px;
color: #4b5563;
font-weight: 500;
}

.form-group input,
.form-group textarea {
width: 90%;
padding: 1em;
border: 1px solid #d1d5db;
border-radius: 6px;
font-size: 14px;
}

.form-group textarea {
min-height: 100px;
}

/* Button Styles */
.primary-button {
background: #2563eb;
color: white;
padding: 8px 16px;
border: none;
border-radius: 6px;
cursor: pointer;
font-weight: 500;
transition: background-color 0.2s;
}

.primary-button:hover {
background: #1d4ed8;
}

.secondary-button {
background: #e5e7eb;
color: #374151;
padding: 8px 16px;
border: none;
border-radius: 6px;
cursor: pointer;
font-weight: 500;
margin-left: 8px;
transition: background-color 0.2s;
}

.secondary-button:hover {
background: #d1d5db;
}

.button-group {
display: flex;
gap: 10px;
margin-top: 20px;
}

/* Profile Photo */
.profile-photo {
width: 36px;
height: 36px;
border-radius: 50%;
object-fit: cover;
overflow: hidden;
aspect-ratio: 1;
}

.profile-photo img {
width: 100%;
height: 100%;
object-fit: cover;
border-radius: 50%;
}

/* Card Styles */
.integration-card,
.subscription-card {
background: white;
border: 1px solid #e5e7eb;
border-radius: 8px;
padding: 20px;
margin-bottom: 20px;
}

/* Error Message */
.error-message {
background: #fee2e2;
border: 1px solid #ef4444;
color: #b91c1c;
padding: 12px;
border-radius: 6px;
margin-bottom: 20px;
}

/* Responsive Styles */
@media (max-width: 768px) {
.react-tabs__tab-list {
  flex-wrap: wrap;
}

.react-tabs__tab {
  flex: 1 1 auto;
  text-align: center;
  padding: 10px;
}

.form-group input,
.form-group textarea {
  font-size: 16px; /* Better for mobile input */
}

.button-group {
  flex-direction: column;
}

.button-group button {
  width: 100%;
  margin: 5px 0;
}
}

.payment-container {
max-width: 600px;
margin: 40px auto;
padding: 20px;
background: white;
border-radius: 8px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.payment-container h1 {
text-align: center;
color: #333;
margin-bottom: 30px;
}

.subscription-details {
padding: 20px;
background: #f8fafc;
border-radius: 6px;
margin-bottom: 30px;
}

.subscription-details h2 {
color: #333;
margin-bottom: 10px;
}

.subscription-details p {
font-size: 24px;
font-weight: bold;
color: #2563eb;
margin-bottom: 20px;
}

.subscription-details ul {
list-style: none;
padding: 0;
}

.subscription-details li {
padding: 8px 0;
color: #4b5563;
display: flex;
align-items: center;
}

.subscription-details li::before {
content: "âœ“";
color: #22c55e;
margin-right: 10px;
font-weight: bold;
}

.checkout-button {
width: 100%;
padding: 12px 24px;
background: #2563eb;
color: white;
border: none;
border-radius: 6px;
font-size: 16px;
font-weight: 500;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
gap: 8px;
transition: background-color 0.2s;
}

.checkout-button:hover {
background: #1d4ed8;
}

.checkout-button:disabled {
background: #93c5fd;
cursor: not-allowed;
}

.spinner {
width: 20px;
height: 20px;
border: 3px solid rgba(255, 255, 255, 0.3);
border-radius: 50%;
border-top-color: white;
animation: spin 1s ease-in-out infinite;
}

.error-message {
padding: 12px;
background: #fee2e2;
border: 1px solid #ef4444;
color: #b91c1c;
border-radius: 6px;
margin-bottom: 20px;
}

@keyframes spin {
to { transform: rotate(360deg); }
}

@media (max-width: 640px) {
.payment-container {
  margin: 20px;
}

.notifications-container, .settings-button, .profile-link, .sign-out-button {
  display: none;
}

.new-order-button
{
  width: 50% !important; 
}

.header-right
{
  position: absolute;
  top: 70px;
  width: 100%;
  gap: 0px !important; 
}

.main-content
{
  margin-top: 2em;
}
}

.main-header {
background-color: #333;
color: white;
padding: 10px 20px;
}

.header-content {
display: flex;
justify-content: space-between;
align-items: center;
}

.header-right {
display: flex;
align-items: center;
gap: 12px;
}

.new-order-button {
display: flex;
align-items: center;
gap: 8px;
padding: 8px 16px;
border: none;
width: 500px;
cursor: pointer;
font-weight: 500;
transition: background-color 0.2s;
background: #a7f46a;
color: black; 
}

.welcome-page {
min-height: 100vh;
background-color: #2828f1;
color: white;
font-family: 'Inter', sans-serif;
}

.welcome-nav {
display: flex;
justify-content: space-between;
align-items: center;
padding: 1.5rem 4rem;
}

@media(max-width: 768px) {
.welcome-nav {
  align-items: left;
}
.nav-logo {
  height: 45px;
  margin-top: -25px;
}
}

.logo-container {
display: flex;
align-items: center;
}

.nav-logo {
height: 45px;
margin-top: -25px;
filter: invert(1) 
}

.nav-links {
display: flex;
align-items: center;
gap: 2rem;
}

.nav-links a {
color: white;
text-decoration: none;
font-size: 1rem;
transition: opacity 0.2s;
}

.nav-links a:hover {
opacity: 0.8;
}

.nav-signin {
padding: 0.5rem 1rem;
}

.nav-get-started {
background-color: #8efc95;
color: #000 !important;
padding: 0.75rem 1.5rem;
border-radius: 8px;
font-weight: 500;
}

.hero-section {
display: flex;
padding: 6rem 4rem;
position: relative;
}

.hero-content {
max-width: 800px;
}

.badge {
display: inline-flex;
align-items: center;
background-color: rgba(255, 255, 255, 0.1);
padding: 0.5rem 1rem;
border-radius: 20px;
margin-bottom: 2rem;
}

.badge-star {
color: #8efc95;
margin-right: 0.5rem;
}

.badge-text {
font-size: 0.875rem;
font-weight: 500;
}

.hero-title {
font-size: 4.5rem;
font-weight: 700;
line-height: 1.1;
margin-bottom: 1.5rem;
}

.hero-subtitle {
font-size: 1.25rem;
opacity: 0.8;
line-height: 1.6;
margin-bottom: 3rem;
}

.hero-buttons {
display: flex;
gap: 1rem;
}

.primary-button {
display: flex;
align-items: center;
gap: 0.5rem;
background-color: #8efc95;
color: #000;
padding: 1rem 2rem;
border-radius: 8px;
font-weight: 500;
text-decoration: none;
transition: transform 0.2s;
}

.primary-button:hover {
transform: translateY(-2px);
}

.arrow-icon {
width: 20px;
height: 20px;
}

.google-button {
display: flex;
align-items: center;
gap: 0.75rem;
background-color: white;
color: #000;
padding: 1rem 2rem;
border-radius: 8px;
border: none;
font-weight: 500;
cursor: pointer;
transition: transform 0.2s;
}

.google-button:hover {
transform: translateY(-2px);
}

.google-icon {
width: 20px;
height: 20px;
}

.hero-decoration {
width: 40%;
}

.curved-line {
position: absolute;
right: 0;
top: 20%;
width: 300px;
height: 300px;
border: 2px solid rgba(255, 255, 255, 0.1);
border-radius: 50%;
}

.star-decoration {
position: absolute;
right: 100px;
top: 100px;
width: 20px;
height: 20px;
background-color: #8efc95;
clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
}

@media (max-width: 768px) {
.welcome-nav {
  padding: 1rem;
  flex-direction: column;
  gap: 1rem;
}

.nav-links {
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.hero-section {
  padding: 2rem 1rem;
}

.hero-title {
  font-size: 2.5rem;
}

.hero-buttons {
  flex-direction: column;
}

.hero-section
{
  display: inline; 
  margin-top: 50px;
}

.logo-container
{
  align-items: left;
}

.hero-decoration {
  width: 100%;
  margin-top: 100px;
  }

}


/* Filter Controls Styling */
.filter-container {
display: flex;
align-items: center;
gap: 24px;
padding: 16px;
background-color: #ffffff;
border: 1px solid #e2e8f0;
border-radius: 12px;
box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
margin-bottom: 24px;
}

/* Status Filter */
.status-filter-group {
display: flex;
align-items: center;
gap: 12px;
}

.filter-container label {
font-size: 0.875rem;
font-weight: 500;
color: #475569;
white-space: nowrap;
}

.filter-container select {
padding: 8px 32px 8px 12px;
font-size: 0.875rem;
border: 1.5px solid #e2e8f0;
border-radius: 8px;
background-color: #ffffff;
color: #0f172a;
cursor: pointer;
appearance: none;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%23475569' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E");
background-repeat: no-repeat;
background-position: right 8px center;
min-width: 160px;
}

.filter-container select:hover {
border-color: #cbd5e1;
}

.filter-container select:focus {
outline: none;
border-color: #3b82f6;
box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

/* Date Filter */
.date-filter {
display: flex;
align-items: center;
gap: 12px;
}

.date-filter input[type="date"] {
padding: 8px 12px;
font-size: 0.875rem;
border: 1.5px solid #e2e8f0;
border-radius: 8px;
color: #0f172a;
background-color: #ffffff;
min-width: 130px;
}

.date-filter input[type="date"]:hover {
border-color: #cbd5e1;
}

.date-filter input[type="date"]:focus {
outline: none;
border-color: #3b82f6;
box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

/* Custom date input calendar icon */
.date-filter input[type="date"]::-webkit-calendar-picker-indicator {
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%23475569' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Crect x='3' y='4' width='18' height='18' rx='2' ry='2'/%3E%3Cline x1='16' y1='2' x2='16' y2='6'/%3E%3Cline x1='8' y1='2' x2='8' y2='6'/%3E%3Cline x1='3' y1='10' x2='21' y2='10'/%3E%3C/svg%3E");
cursor: pointer;
}

/* Responsive adjustments */
@media (max-width: 768px) {
.filter-container {
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
}

.status-filter-group,
.date-filter {
  flex-direction: column;
  align-items: stretch;
}

.date-filter input[type="date"] {
  width: 100%;
}
}

/* Optional: Add status color indicators */
.filter-container select option[value="not-completed"] { color: #6b7280; }
.filter-container select option[value="pending"] { color: #eab308; }
.filter-container select option[value="ordered"] { color: #3b82f6; }
.filter-container select option[value="received"] { color: #10b981; }
.filter-container select option[value="completed"] { color: #22c55e; }
.filter-container select option[value="cancelled"] { color: #ef4444; }

.checkbox-label {
display: flex;
align-items: center;
cursor: pointer;
}

.form-input[type="checkbox"] {
width: auto;
margin-right: 8px;
}

textarea.form-input {
min-height: 80px;
resize: vertical;
}

.order-details-page {
padding: 2rem;
max-width: 1200px;
margin: 0 auto;
}

.header {
display: flex;
justify-content: space-between;
align-items: center;
margin-bottom: 2rem;
}

.action-buttons {
display: flex;
gap: 1rem;
}

.action-buttons button {
display: flex;
align-items: center;
gap: 0.5rem;
padding: 0.5rem 1rem;
border: none;
border-radius: 0.25rem;
background-color: #f3f4f6;
cursor: pointer;
}

.order-info {
display: grid;
grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
gap: 2rem;
margin-bottom: 2rem;
padding: 1.5rem;
background-color: white;
border-radius: 0.5rem;
box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.items-section {
margin-bottom: 2rem;
}

.items-section table {
width: 100%;
border-collapse: collapse;
margin-top: 1rem;
}

.items-section th,
.items-section td {
padding: 0.75rem;
text-align: left;
border-bottom: 1px solid #e5e7eb;
}

.comments-section {
background-color: white;
padding: 1.5rem;
border-radius: 0.5rem;
box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.comments-list {
margin-bottom: 1rem;
max-height: 400px;
overflow-y: auto;
}

.comment {
padding: 1rem;
border-bottom: 1px solid #e5e7eb;
}

.comment-header {
display: flex;
justify-content: space-between;
margin-bottom: 0.5rem;
font-size: 0.875rem;
color: #6b7280;
}

.add-comment {
display: flex;
gap: 1rem;
}

.add-comment textarea {
flex: 1;
min-height: 100px;
padding: 0.5rem;
border: 1px solid #e5e7eb;
border-radius: 0.25rem;
}

.add-comment button {
align-self: flex-start;
padding: 0.5rem 1rem;
background-color: #3b82f6;
color: white;
border: none;
border-radius: 0.25rem;
cursor: pointer;
}

.modal {
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: rgba(0, 0, 0, 0.5);
display: flex;
justify-content: center;
align-items: center;
z-index: 1000;
}

.modal-content {
background-color: white;
padding: 2rem;
border-radius: 0.5rem;
width: 100%;
max-width: 800px;
}

.form-group {
margin-bottom: 1rem;
}

.form-group label {
display: block;
margin-bottom: 0.5rem;
}

.form-group select,
.form-group input {
width: 100%;
padding: 0.5rem;
border: 1px solid #e5e7eb;
border-radius: 0.25rem;
}

.modal-actions {
display: flex;
justify-content: flex-end;
gap: 1rem;
margin-top: 1.5rem;
}

.modal-actions button {
padding: 0.5rem 1rem;
border: none;
border-radius: 0.25rem;
cursor: pointer;
}

.modal-actions button:first-child {
background-color: #3b82f6;
color: white;
}

.modal-actions button:last-child {
background-color: #e5e7eb;
}

.timing-grid {
display: grid;
grid-template-columns: repeat(2, 1fr);
gap: 1rem;
}

.timing-grid div {
margin-bottom: 0.5rem;
}

.timing-grid label {
display: block;
font-size: 0.875rem;
color: #6b7280;
margin-bottom: 0.25rem;
}

.timing-grid p {
font-weight: 500;
margin: 0;
}

.edit-input {
width: 80px;
padding: 4px 8px;
border: 1px solid #ddd;
border-radius: 4px;
}

.action-buttons {
display: flex;
gap: 8px;
}

.action-buttons button {
padding: 4px 8px;
border: none;
border-radius: 4px;
cursor: pointer;
}

.action-buttons button:first-child {
background-color: #3b82f6;
color: white;
}

.action-buttons button:last-child {
background-color: #ef4444;
color: white;
}

.notification-bell {
position: relative;
}

.bell-button {
background: none;
border: none;
padding: 8px;
cursor: pointer;
position: relative;
}

.notification-badge {
position: absolute;
top: 0;
right: 0;
background-color: #ef4444;
color: white;
border-radius: 50%;
padding: 2px 6px;
font-size: 12px;
min-width: 18px;
}

.notification-dropdown {
position: absolute;
top: 100%;
right: 0;
width: 320px;
background: white;
border-radius: 8px;
box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
max-height: 480px;
overflow-y: auto;
z-index: 50;
}

.notification-header {
display: flex;
justify-content: space-between;
align-items: center;
padding: 12px 16px;
border-bottom: 1px solid #e5e7eb;
}

.mark-all-read {
font-size: 12px;
color: #3b82f6;
background: none;
border: none;
cursor: pointer;
}

.notification-list {
padding: 8px 0;
}

.notification-item {
padding: 12px 16px;
cursor: pointer;
transition: background-color 0.2s;
}

.notification-item:hover {
background-color: #f3f4f6;
}

.notification-item.unread {
background-color: #f0f9ff;
}

.notification-title {
font-weight: 500;
margin-bottom: 4px;
}

.notification-message {
font-size: 14px;
color: #6b7280;
margin-bottom: 4px;
}

.notification-time {
font-size: 12px;
color: #9ca3af;
}

.no-notifications {
padding: 16px;
text-align: center;
color: #6b7280;
}

.signin-white input::placeholder {
color:  rgb(255 255 255 / 0.8);
margin-left: 5px;
}