.sidebar {
  position: fixed;
  right: 0;
  top: 0;
  height: 900vh;
  width: 580px;
  background-color: #ffffff;
  box-shadow: -4px 0 16px rgba(0, 0, 0, 0.08);
  transform: translateX(100%);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 1000;
  overflow-y: auto;
}

.sidebar.show {
  transform: translateX(0);
}

.sidebar-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 24px;
}

/* Header styling */
.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #f1f5f9;
  margin-bottom: 24px;
}

.sidebar-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #0f172a;
}

.close-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border: none;
  background: transparent;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.close-button:hover {
  background-color: #f8fafc;
}

/* Card styling */
.card {
  background: #ffffff;
  border: 1px solid #f4edf9;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 20px;
  transition: box-shadow 0.2s;
}

.card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

/* Form elements */
.form-group {
  margin-bottom: 16px;
}

.form-label {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  color: #475569;
  margin-bottom: 8px;
}

.form-input,
.form-select {
  width: 100%;
  padding: 10px 14px;
  border: 1.5px solid #f4edf9;
  border-radius: 8px;
  font-size: 0.875rem;
  color: #0f172a;
  background-color: #ffffff;
  transition: all 0.2s;
}

.form-input:focus,
.form-select:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

.form-input::placeholder {
  color: #94a3b8;
}

/* Autocomplete styling */
.autocomplete-container {
  position: relative;
}

.autocomplete-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #ffffff;
  border: 1px solid #f4edf9;
  border-radius: 8px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  margin-top: 4px;
  max-height: 240px;
  overflow-y: auto;
  z-index: 1000;
}

.autocomplete-item {
  padding: 12px 16px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.autocomplete-item:hover {
  background-color: #f8fafc;
}

.contact-name {
  font-weight: 500;
  color: #0f172a;
  margin-bottom: 4px;
}

.contact-details {
  font-size: 0.813rem;
  color: #64748b;
}

/* Product section styling */
.products-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.section-title {
  font-size: 1.125rem;
  font-weight: 600;
  color: #0f172a;
}

.add-button {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: #f8fafc;
  border: 1.5px solid #f4edf9;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  color: #0f172a;
  cursor: pointer;
  transition: all 0.2s;
}

.add-button:hover {
  background-color: #f1f5f9;
  border-color: #cbd5e1;
}

/* Product item styling */
.product-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #f4edf9;
  border-radius: 12px;
  margin-bottom: 12px;
}

.product-info {
  flex-grow: 1;
}

.product-name {
  font-weight: 500;
  color: #0f172a;
  margin-bottom: 4px;
}

.product-quantity {
  font-size: 0.875rem;
  color: #64748b;
}

.product-actions {
  display: flex;
  gap: 8px;
}

.action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: none;
  background: transparent;
  border-radius: 6px;
  color: #64748b;
  cursor: pointer;
  transition: all 0.2s;
}

.action-button:hover {
  background-color: #f8fafc;
  color: #0f172a;
}

/* Edit mode styling */
.edit-mode {
  padding: 16px;
}

.edit-controls {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 12px;
}



.total-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 16px;
  background-color: #f8fafc;
  border-radius: 8px;
}

.total-label {
  font-weight: 500;
  color: #0f172a;
}

.total-amount {
  font-size: 1.25rem;
  font-weight: 600;
  color: #0f172a;
}

.create-order-button {
  width: 100%;
  padding: 14px;
  background-color: #3b82f6;
  border: none;
  border-radius: 8px;
  color: #ffffff;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.create-order-button:hover {
  background-color: #2563eb;
}

.create-order-button:disabled {
  background-color: #94a3b8;
  cursor: not-allowed;
}

/* Error message styling */
.error-message {
  padding: 12px 16px;
  background-color: #fef2f2;
  border: 1px solid #fecaca;
  border-radius: 8px;
  color: #dc2626;
  margin-bottom: 20px;
}

/* Loading state */
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  color: #64748b;
}

/* Scrollbar styling */
.sidebar::-webkit-scrollbar {
  width: 8px;
}

.sidebar::-webkit-scrollbar-track {
  background: #f1f5f9;
}

.sidebar::-webkit-scrollbar-thumb {
  background: #cbd5e1;
  border-radius: 4px;
}

.sidebar::-webkit-scrollbar-thumb:hover {
  background: #94a3b8;
}

/* Animation keyframes */
@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .sidebar {
    width: 100%;
  }
  
  .sidebar-content {
    padding: 16px;
  }
  
  .product-item {
    flex-direction: column;
    gap: 12px;
  }
  
  .product-actions {
    width: 100%;
    justify-content: flex-end;
  }
}


.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999; /* Just below the sidebar */
  animation: fadeIn 0.2s ease-out;
}

.sidebar {
  z-index: 1000; /* Ensure sidebar is above overlay */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
